import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { WATCH_TIME_TYPE } from '../../config/const.config';

const WatchTimeType = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  disabled,
  sx,
  error,
}) => (
  <FormControl
    fullWidth
    error={error !== ''}
    variant="standard"
    sx={{ width: { xs: '50%', sm: '30%', md: '15%' } }}
  >
    <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id={id}
      name={name}
      value={defaultValue}
      label={label}
      onChange={onChange}
      disabled={disabled}
      sx={sx}
      style={{ margin: 0 }}
    >
      {WATCH_TIME_TYPE.map((k) => (
        <MenuItem key={`${id}-v-${k}`} value={k.value}>
          {k.label}
        </MenuItem>
      ))}
    </Select>
    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

WatchTimeType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  sx: PropTypes.shape(),
  error: PropTypes.string,
};
WatchTimeType.defaultProps = {
  disabled: false,
  sx: {},
  error: '',
};

export default WatchTimeType;
